*{
  box-sizing: border-box;
}

:root {
  --color-primary: #f8f8f2c0;
  --background: #1e2029;
  --gray: #d8d9da9b;
  --current-line: #44475a5a;
  --purple: #bd93f981;
  --pink: #FF79C6;
  --orange: #FFB86C;
}

html {
  height: 100%;
  width: 100%;
  position: relative
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
  color: white;
  font-weight: normal;
  text-align: center;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  z-index: 0;
  min-height: 100%;
  position: relative;
  padding: 0 0 130px;
}
